// 2024-11-21 k_nagayama submit追加
import { filesize } from "filesize";
import { ReactNode, useCallback, useMemo, useState } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { FaFileDownload } from 'react-icons/fa';
import { PiSpinnerGap } from 'react-icons/pi';
import { BaseProps } from '../@types/common';
import useSubmitFiles from '../hooks/useSubmitFiles';
import { formatDatetime } from '../utils/DateUtils';
import Button from './Button';
import FileIcon from './FileIcon';

type Props = BaseProps & {
  file: {
    id: string;
    title: string;
    available: boolean;
    lastModifiedTime: Date;
    size: number
    syncStatus: "UPLOADING" | "SUCCEEDED" | "FAILED"
  };
  onClick: (botId: string) => void;
  children?: ReactNode;
};

const ListItemFile: React.FC<Props> = (props) => {
  const [isDownloading, setIsDwonloading] = useState(false);
  const [downloadProgress, setDownloadProgress] = useState(0);

  const {
    getPresignedDownloadUrl,
  } = useSubmitFiles();


  // ReadableStreamをサポートしているか確認
  const isReadableStreamSupported = useMemo(() => {
    return !!(
      window.ReadableStream &&
      window.Response &&
      'body' in Response.prototype
    );
  }, []);

  const downloadWithProgress = useCallback(async (responseBody: ReadableStream<Uint8Array>, total: number) => {
    const reader = responseBody.getReader();

    let loadedBytes = 0;
    // カスタムの読み取りストリーム
    const stream = new ReadableStream({
      async start(controller) {
        // eslint-disable-next-line no-constant-condition
        while (true) {
          const { done, value } = await reader.read();

          if (done) {
            controller.close();
            break;
          }

          // チャンクの追加と進行状況の更新
          controller.enqueue(value);
          loadedBytes += value.length;

          const progress = Math.round((loadedBytes / total) * 100);
          setDownloadProgress(progress);
        }
      }
    });
    return await new Response(stream).blob();
  }, [])

  const downloadFile = useCallback(async () => {
    setIsDwonloading(true);
    const url = (await getPresignedDownloadUrl(props.file.id)).data;

    setDownloadProgress(0);

    const response = await fetch(url);

    // コンテンツ長の取得
    const contentLength = response.headers.get('Content-Length') as string;
    const total = parseInt(contentLength, 10);

    // レスポンスがReadableStreamをサポートしているか確認
    let blob;
    if (isReadableStreamSupported && response.body && total) {
      blob = await downloadWithProgress(response.body, total);
    } else {
      // ブラウザに直接ダウンロードさせる
      blob = await response.blob();
    }

    // ダウンロード用のリンクを作成
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = props.file.title;

    // クリックしてダウンロード
    document.body.appendChild(link);
    link.click();

    // クリーンアップ
    document.body.removeChild(link);
    window.URL.revokeObjectURL(link.href);

    setDownloadProgress(0);
    setIsDwonloading(false);
  }, [downloadWithProgress, getPresignedDownloadUrl, isReadableStreamSupported, props.file.id, props.file.title])


  const getButtonIcon = useCallback(() => {
    if (props.file.syncStatus == "UPLOADING") {
      return (<span className="relative">
        <FaFileDownload className="opacity-30" />
        <PiSpinnerGap className="absolute inset-0 animate-spin" />
      </span>)
    }
    if (isDownloading) {
      if (downloadProgress) {
        return <CircularProgressbar value={downloadProgress} className='size-4' />
      }
      return <PiSpinnerGap className="animate-spin" />
    } else {
      return <FaFileDownload />
    }
  }, [downloadProgress, isDownloading, props.file.syncStatus])


  return (
    <div
      key={props.file.id}
      className={`${props.className ?? ''
        } relative flex w-full justify-between border-b border-light-gray`}>
      <div>
        <FileIcon
          className="size-full px-3"
          fileName={props.file.title} />
      </div>
      <div
        className={`h-full grow bg-aws-paper p-2 ${props.file.available
          ? 'cursor-pointer hover:brightness-90'
          : 'text-aws-font-color/30'
          }`}
        onClick={() => {
          if (props.file.available) {
            props.onClick(props.file.id);
          }
        }}>
        <div className="w-full overflow-hidden text-ellipsis text-sm font-semibold">
          {props.file.title}
        </div>
        {formatDatetime(props.file.lastModifiedTime)}
      </div>

      <div className="absolute right-0 flex h-full justify-between ">
        <div className="w-10 bg-gradient-to-r from-transparent to-aws-paper"></div>
        <div className="flex items-center  gap-2 bg-aws-paper pl-2">
          {props.children}
          {filesize(props.file.size)}
          <div className="mr-5 flex justify-end">
            <Button
              onClick={downloadFile}
              disabled={isDownloading || props.file.syncStatus != "SUCCEEDED"}
              className="opacity-100"
              outlined>
              {getButtonIcon()}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListItemFile;
// 2024-11-21 k_nagayama submit追加 ここまで
