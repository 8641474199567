// 2024-11-21 k_nagayama submit追加
import { FaFile, FaFileAudio, FaRegFileImage, FaRegFileVideo } from 'react-icons/fa';
import { BaseProps } from '../@types/common';
import { getFileType } from '../utils/FileUtils';

type Props = BaseProps & {
  fileName: string;
};

const FileIcon: React.FC<Props> = (props) => {
  switch (getFileType(props.fileName)) {
    case 'VIDEO':
      return <FaRegFileVideo className={props.className} />
    case 'IMAGE':
      return <FaRegFileImage className={props.className} />
    case 'AUDIO':
      return <FaFileAudio className={props.className} />
    case 'OTHER':
    default:
      return <FaFile className={props.className} />
  }
};

export default FileIcon;
// 2024-11-21 k_nagayama submit追加 ここまで
