// 2024-11-21 k_nagayama submit追加
import React from 'react';
import ReactPlayer from 'react-player';
import { BaseProps } from '../@types/common';
import { getFileType } from '../utils/FileUtils';
import ModalDialog from './ModalDialog';
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer"
import "@cyntler/react-doc-viewer/dist/index.css";
import { useTranslation } from 'react-i18next';

type Props = BaseProps & {
  fileName: string;
  fileUrl: string;
  isOpen: boolean;
  onClose: () => void;
};

const DialogFilePreview: React.FC<Props> = (props) => {
  const { i18n } = useTranslation();

  const preview = (fileUrl: string, fileName: string) => {
    switch (getFileType(fileName)) {
      case 'VIDEO':
        return <ReactPlayer url={fileUrl} controls></ReactPlayer>
      case 'IMAGE':
        return <img src={fileUrl} ></img>
      case 'AUDIO':
        return <ReactPlayer url={fileUrl} controls></ReactPlayer>
      case 'DOC':
        return <DocViewer
          // @ts-expect-error 言語設定が存在しない可能性あり
          language={i18n.language}
          prefetchMethod="GET"
          pluginRenderers={DocViewerRenderers}
          documents={[{ uri: fileUrl }]}></DocViewer>
      case 'OTHER':
      default:
        return <></>
    }
  }

  return (
    <ModalDialog
      title={props.fileName}
      {...props}>
      <div className="flex flex-col gap-2">
        {preview(
          props.fileUrl,
          props.fileName
        )}
      </div>
    </ModalDialog>
  );
};

export default DialogFilePreview;
// 2024-11-21 k_nagayama submit追加 ここまで
