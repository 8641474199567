// 2024-11-21 k_nagayama submit追加
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useSubmitFiles from '../hooks/useSubmitFiles';
import ListItemUserFiles from '../components/ListItemUserFiles';
import { PiCircleNotch } from 'react-icons/pi';
import ListItemCollapse2 from '../components/ListItemCollapse2';

const AdminSubmitFilesPage: React.FC = () => {
  const { t } = useTranslation();

  const [users, setUsers] = useState<{ userId: string, username: string }[]>([]);
  const [isLoading, setLoading] = useState(true);

  const {
    getSubmitedUserList,
  } = useSubmitFiles();

  useEffect(() => {
    (async () => {
      setLoading(true);
      setUsers((await getSubmitedUserList()).data)
      setLoading(false);
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <div className="flex h-full justify-center">
        <div className="w-2/3">
          <div className="size-full pt-8">
            <div className="flex items-end justify-between">
              <div className="flex items-center gap-2">
                <div className="text-xl font-bold">{t('submitFiles.label.myFiles')}</div>
              </div>
            </div>
            <div className="mt-2 border-b border-gray"></div>

            <div className="h-4/5 overflow-x-hidden overflow-y-scroll border-b border-gray pr-1 scrollbar-thin scrollbar-thumb-aws-font-color/20 ">
              {users?.length === 0 && (
                <>
                  {isLoading ? (
                    <div className="flex flex-col items-center p-4">
                      <div className="animate-spin">
                        <PiCircleNotch size={100} />
                      </div>
                    </div>
                  ) : (
                    <div className="flex size-full items-center justify-center italic text-dark-gray">
                      {t('submitFiles.label.noFiles')}
                    </div>
                  )}
                </>
              )}
              {users?.map((user) => (
                <>
                  <ListItemCollapse2
                    title={user.username}>
                    <ListItemUserFiles
                      userId={user.userId}
                      onClick={() => { }}
                    >
                    </ListItemUserFiles>
                  </ListItemCollapse2>
                </>
              ))}
            </div>
          </div>
        </div>
      </div >
    </>
  );
};

export default AdminSubmitFilesPage;
// 2024-11-21 k_nagayama submit追加 ここまで
