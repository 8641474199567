// 2024-11-21 k_nagayama submit追加
import { useCallback } from 'react';
import useSubmitFilesApi from './useSubmitFilesApi';
import { produce } from 'immer';
import { SubmitFileListItem } from '../@types/submit';

const useSubmitFiles = (userId?: string) => {
  const { files, getPresignedUploadUrl, uploadFile, getPresignedDownloadUrl, getSubmitedUserList } = useSubmitFilesApi();

  const { data: myFiles, mutate: mutateMyFiles, isLoading } = files({ userId });

  const postSubmitFile = useCallback(async (file: File) => {
    const presignedUrl = (await getPresignedUploadUrl(file.name)).data;
    mutateMyFiles(
      produce(myFiles, (draft) => {
        draft?.unshift({
          id: "",
          title: file.name,
          lastModifiedTime: new Date(),
          size: file.size,
          syncStatus: 'UPLOADING',
          available: false
        } as SubmitFileListItem);
      }),
      {
        revalidate: false,
      }
    );
    await uploadFile(presignedUrl, file);
    mutateMyFiles()
  }, [getPresignedUploadUrl, mutateMyFiles, myFiles, uploadFile])

  return {
    myFiles,
    isLoading,
    mutateMyFiles,
    postSubmitFile,
    getPresignedDownloadUrl,
    getSubmitedUserList
  };
};

export default useSubmitFiles;
// 2024-11-21 k_nagayama submit追加 ここまで
